.Footer {
    color: #939598;
    background-color: #2d2e2d;
    padding: 20px;
}

.Footer h4 {
    margin-top: 0;
    font-size: 18px;
}

.Footer p {
    margin-bottom: 25px;
}

.Footer a {
    color: #ffffff;
    text-decoration: none;
}