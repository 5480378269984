.QACallout {
    overflow: hidden;
}

.QACallout .Callout-Content {
    border-top: 1px solid #ccc;
}

.QACallout .QA-Question-Section {
    max-height: 0;
    padding: 0 20px;
    border: 1px solid #ccc;
    display: none;
}

.QACallout .QA-Question-Section.expand {
    max-height: none;
    padding: 20px;
    display: block;
}

.QACallout .qa-button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #000;
    color: #fff;
    user-select: none;
    margin: 15px 0;
}

.QACallout .Callout-disclaimer {
    font-weight: 700;
    font-size: 13px;
    width: auto;
    margin: 15px 0;
}