.QAPage.stickyPageMargin {
    margin-top: 45px;
}

.QAPage .HeroImage {
    width: 100%;
    margin-bottom: -7px;
    border-bottom: 5px solid #64ab1f;
}

.QAPage .socialmedia-img {
    margin-right: 20px;
    cursor: pointer;
}

.QAPage button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #000;
    color: #fff;
}