.Callout {
    display: flex;
    font-size: 16px;
    height: 100%;
}

.Callout.reverse {
    flex-direction: row-reverse;
}

.Callout .Callout-Image {
    width: 50%;
}

.Callout .Callout-Image img {
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    float: left;
}

.Callout .Callout-Content {
    width: calc(50% - 40px);
}

.Callout .Callout-Content {
    padding: 20px;
}

@media only screen and (max-width: 1400px) {
    .Callout .Callout-Content {
        font-size: 1.3vw;
    }

    .Callout button {
        font-size: 1.1vw !important;
    }
    .Callout h2 {
        font-size: 1.0em;
        margin-block: 0.2em;

    }
    .Callout h3 {
        font-size: 0.90em;
        margin-block: 0;

    }


}

@media only screen and (max-width: 768px) {
    .Callout {
        flex-direction: column !important;
    }

    .Callout .Callout-Image {
        width: 100%;
        height: 100%;
    }

    .Callout .Callout-Content {
        width: calc(100% - 40px);
        font-size: 16px;
    }

    .Callout button {
        font-size: 16px !important;
        width: 100% !important;
    }
}