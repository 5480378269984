.Questionaire {
	border-top: 1px solid #555555;
}

.Questionaire .required-label {
	font-size: 20px;
	margin-top: 20px;
}

.Questionaire .required {
	color: #ff0000;
}

.Questionaire hr {
	margin: 20px;
}

.Questionaire .Info-Question-Container,
.Questionaire .Survey-Question-Container {
	padding: 20px;
}

.Questionaire .Info-Question {
	width: calc(50% - 60px);
	display: inline-block;
	padding: 20px 30px 5px 30px;
}

.Questionaire .Info-Question input {
	width: calc(100% - 15px);
	font-size: 16px;
	padding: 5px;
}

.Questionaire .Info-Question.cca_number {
	width: 17%;
}

.Questionaire .Info-Question select {
	width: 100%;
	font-size: 16px;
	padding: 5px;
}

.Questionaire .Info-Question .question-label {
	margin-bottom: 5px;
}

.Questionaire form input:invalid,
.Questionaire form select:invalid {
	outline: 2px solid red;
}

.Questionaire .Survey-Question {
	margin-top: 20px;
}

.Questionaire .Survey-Question textarea {
	width: calc(100% - 12px);
	height: 97px;
	font-size: 18px;
	padding: 5px;
	resize: none;
	box-sizing: border-box;
}

.Questionaire .other-text {
	padding: 5px;
	width: 200px;
}

.Questionaire .Consent-Question-Container {
	padding: 0 20px;
}

.Questionaire .Consent-Question.crop_consultant {
	width: 28%;
	float: right;
	font-size: 16px;
	margin-top: 59px;
}

.Questionaire .Consent-Question.crop_consultant .Centered-Answer {
	margin: 0px 0;
}

.Questionaire .CEM-Consent-Box {
	outline: solid 1px black;
	padding: 1px 40px;
}

.Questionaire .Centered-Answer {
	display: table;
	margin: 17px 0;
}

.Questionaire .Centered-Answer-Child {
	display: table-cell;
	vertical-align: middle;
	font-size: 16px;
	padding-right: 10px;
}

.Questionaire .submit-button {
	width: 200px;
	height: 40px;
	font-size: 20px;
	text-align: center;
	line-height: 40px;
	background: #999999;
	color: #cccccc;
	margin: 20px;
	border: none;
	cursor: not-allowed;
}
.Questionaire .submit-button.valid {
	background: #c50022;
	color: #ffffff;
	cursor: pointer;
}
.Questionaire .submit-button.loading {
	background-image: url('../../images/register/loading-spinner.gif');
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: not-allowed;
}

@media only screen and (max-width: 1372px) {
	.Questionaire .Info-Question.cca_number {
		width: 15%;
	}
	.Questionaire .Consent-Question.crop_consultant {
		width: 30%;
		float: right;
	}

	.Questionaire .Centered-Answer {
		display: table;
		margin: 16px 0;
	}
}

@media only screen and (max-width: 1240px) {
	.Questionaire .Info-Question.cca_number {
		width: 19%;
	}
	.Questionaire .Consent-Question.crop_consultant {
		width: 25%;
		float: right;
		margin-top: 59px;
	}

	.Questionaire .Centered-Answer {
		display: table;
		margin: 16px 0;
	}
}

@media only screen and (max-width: 1024px) {
	.Questionaire .Info-Question.cca_number {
		width: 43.5%;
	}
	.Questionaire .Consent-Question.crop_consultant {
		width: 47%;
		float: right;
		margin-top: 41px;
	}
}

@media only screen and (max-width: 768px) {
	.Questionaire .Info-Question {
		width: calc(100% - 60px);
	}

	.Questionaire .Consent.Centered-Answer-Child {
		display: block;
	}

	.Questionaire .other-text {
		width: auto;
	}

	.Questionaire .Info-Question.cca_number {
		width: 92%;
	}

	.Questionaire .Consent-Question.crop_consultant {
		width: 83%;
		float: left;
		margin-left: 28px;
		margin-top: 8px;
	}

	.Questionaire .Centered-Answer {
		display: table;
		margin: 15px 0;
	}
}

@media only screen and (max-width: 767px) {
	.Questionaire .Info-Question.cca_number {
		width: 85%;
	}

	.Questionaire .Consent-Question.crop_consultant {
		width: 83%;
		float: left;
		margin-left: 28px;
	}

	.Questionaire .Centered-Answer {
		display: table;
		margin: 15px 0;
	}
}
