.TopNavItemLink {
    text-decoration: none;
}

.TopNavItem {
    display: inline-block;
    line-height: 45px;
    padding: 0 20px;
    font-weight: bold;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    outline: none;
}

.TopNavItemLink.active .TopNavItem {
    color: #000000;
}

.TopNavItemLink:not(.active) .TopNavItem {
    color: #65ac1e;
}


@media only screen and (max-width: 768px) {
    .TopNavItems .TopNavItem {
        display: block;
        border-bottom: 2px solid #dcdcdc;
    }
}