.RegisterCallout .LearnMoreLink {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #c50022;
    color: #ffffff;
}