.PastCallout {
    color: #2e2e2e;
}

.PastCallout .Past-Callout-Button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: #000000;
    margin: 0 10px 10px 0;
}

.VideoContainer, .WatchedContainer, .SubmittedContainer {
    padding: 20px;
    box-shadow: inset 0 0 40px #000000;
}

.WebinarVideo {
    width: 100%;
    min-height: 750px;
}

.ShareContainer {
    display: table;
    margin: 5px 0;
}

.ShareContainer-Child {
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
    padding-right: 25px;
}

.ShareContainer-Child img {
    cursor: pointer;
    padding-top: 5px;
}

p.ShareContainer-Child {
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .PastCallout .Past-Callout-Button {
        font-size: 20px;
        width: 100%;
    }

    .WebinarVideo {
        min-height: 250px;
    }
}