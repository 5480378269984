.WebinarPage.stickyPageMargin {
    margin-top: 45px;
}

.WebinarPage .HeroImage {
    width: 100%;
    margin-bottom: -7px;
}

.WebinarPage .RegisterToggle {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    display: flex;
}

.WebinarPage .RegisterToggle .reg-toggle {
    *width: calc(50% - 20px);
    width:50%;
    display: inline-block;
    padding: 10px;
}

.WebinarPage .RegisterToggle .reg-inprogress {
    padding: 10px;
}

.WebinarPage .half-div {
    width: calc(100% - 40px);
    padding: 20px;
    text-align: center;
}

.WebinarPage .half-div p {
    font-size: 18px;
}

.WebinarPage .WebinarInfo .main_message {
    text-align: center;
    padding: 0 20px;
}

.WebinarPage .WebinarInfo .WebinarTimer {
    font-size: 20px;
    text-align: center;
}

.WebinarPage .WebinarInfo .Countdown {
    font-weight: 700;
    margin: 25px;
}

.WebinarPage .WebinarInfo .Countdown p {
    font-weight: 100;
}

.WebinarPage .WebinarInfo .WebinarDetails {
    font-size: 20px;
    padding: 20px;
}

.WebinarPage .WebinarInfo .WebinarDetails ul {
    font-size: 20px;
}

.WebinarPage .WebinarTechnical {
    text-align: center;
    margin-bottom: 50px;
    padding: 0 20px;
}

.WebinarPage .WebinarTechnical p {
    font-size: 20px;
}

.WebinarPage .WebinarTechnical .FAQ-Button{
    color: #ffffff;
    background-color: #65ac1e;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 700;
    border: none;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 5px;
}

.WebinarPage .WebinarTechnical .Download-Button {
    height: 60px;
    width: 170px;
    margin-bottom: 0;
    margin-right: 10px;
}


.WebinarPage .WebinarCallouts .Callout-Half {
    height: 300px;
    width: 100%;
}

.WebinarPage .Callout-Half .Default-Callout-Content {
    padding: 20px;
}

.WebinarPage .Callout-Half .Default-Callout-Content button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    background-color: #000000;
    color: #ffffff;
}

.WebinarPage .SubmissionSection {
    text-align: center;
    padding: 20px;
}

.WebinarPage .SubmissionSection img.register-check {
    width: 120px;
}

.WebinarPage .flex {
    display: flex;
}

.WebinarPage .socialmedia-img {
    margin-right: 20px;
    cursor: pointer;
}

.WebinarPage .joinnow-button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    margin-right: 10px;
    padding: 10px 20px;
    
    text-align: center;
    background-color: #c50022;
    color: #fff;
    cursor: pointer;
}


.black-on-orange {
    background-color: #f79924;
    color: #000000;
}

.white-on-black {
    background-color: #000000;
    color: #ffffff;
}

.grey-on-grey {
    background-color: #cccccc;
    color: #a6a6a6;
}

.black-on-grey {
    background-color: #ebebeb;
    color: #000000;
}

.flex {
    display: flex;
}

.table {
    display: table;
    width: 100%;
    height: 100%;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}


@media only screen and (max-width: 768px) {
    .WebinarPage .RegisterToggle .reg-toggle {
        font-size: 16px;
    }

    .WebinarPage .flex {
        display: block;
    }

    .WebinarPage .WebinarCallouts .Callout-Half {
        height: 100%;
    }
}