.RegLookupPage {
	padding: 30px;
}

.RegLookupPage h2,
.RegLookupPage h3 {
	margin: 0;
}

.RegLookupPage .current {
	margin: 15px 0;
}

.RegLookupPage p {
	margin: 10px 0;
}

.email-submit {
	color: #ffffff;
	background-color: #65ac1e;
	padding: 15px 20px;
	font-size: 18px;
	font-weight: 700;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	width: 150px;
}

.lookup-form {
	display: flex;
	flex-direction: column;
	height: 100px;
	justify-content: space-between;
	margin-bottom: 40px;
}

.email-input {
	font-size: 16px;
	padding: 5px;
}
