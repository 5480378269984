.Modal .Modal-Background {
    background-color: #000000;
    opacity: 0.5;
    z-index: 999;
    height: 5000px;
    width: 5000px;
    position: fixed;
    top: 0;
    left: 0;
}

.Modal .Modal-Content {
    opacity: 2;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: TranslateX(-50%) TranslateY(-50%);
    background-color: #ffffff;
    border: 2px solid #65ac1e;
    padding: 20px;
    z-index: 9999;
}

.Modal .Modal-Content .okay-button-wrapper {
    text-align: center;
    padding: 10px;
}

.Modal .Modal-Content .okay-button {
    padding: 10px 25px;
    color: #ffffff;
    background-color: #65ac1e;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    user-select: none;
}

.Modal .Modal-Content .close-button {
    float: right;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    color: #555555;
}

.Modal .Modal-Content .close-button:hover {
    color: #000000;
}