.QAQuestion {
    border: 1px solid #64ab1f;
    margin-bottom: 10px;
}

.QAQuestion .QuestionHeader {
    user-select: none;
    cursor: pointer;
    display: table;
}

.QAQuestion .QuestionHeader h3 {
    margin: 0;
    padding: 10px;
    display: table-cell;
    width: 100%;
}

.QAQuestion .QuestionHeader .QuestionArrow {
    display: table-cell;
    vertical-align: middle;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    transition: transform 0.5s;
}

.QAQuestion .QuestionHeader .QuestionArrow.flipped {
    transform: rotate(180deg);
}

.QAQuestion.expand .QuestionHeader {
    background-color: #64ab1f;
    color: #ffffff;
}

.QAQuestion .QAQuestionContent {
    overflow: hidden;
    transition: 0.5s;
}

.QAQuestion .QAQuestionContent:not(.hidden) {
    padding: 20px;
    opacity: 1;
}

.QAQuestion .QAQuestionContent.hidden {
    max-height: 0;
    opacity: 0;
    padding: 0 20px;
}