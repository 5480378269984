.TopNav {
    width: 100%;
}

.TopNav .LogoBar {
    background-color: #64ab1f;
    height: 80px;
}

.TopNav .LogoBar .BASFLogo{
    height: 80px;
    cursor: pointer;
    width: 165px;
    background-size: cover;
    display: inline-block;
}

.TopNav .TopNavItemBar {
    background-color: #ffffff;
    height: 45px;
    width: 100%;
    max-width: 1400px;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
}

.TopNav .TopNavItemBar.stickyNav {
    top: 0;
    position: fixed;
}

.TopNav .TopNavItems {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
    overflow: hidden;
}

.MenuExpand {
    display: none;
    background-position: center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    padding: 0 0 0 10px;
}

@media only screen and (max-width: 768px) {
    .TopNav .TopNavItemBar .TopNavItems {
        background: #f0f0f0;
        position: relative;
    }

    .MenuExpand {
        display: block;
    }

    .hiddenNav {
        max-height: 0 !important;
        transition: max-height 0.5s !important;
    }
}