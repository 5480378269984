.HomePage.stickyPageMargin {
    margin-top: 45px;
}

.HomePage .Twitter-Wrapper {
    /*height: 300px;
    overflow-y: scroll;*/
}
.HomePage .flex {
    display: flex;
}

.HomePage .HeroImage {
    width: 100%;
    margin-bottom: -7px;
}

.HomePage .Intro {
    padding: 20px;
}

.HomePage .Intro p {
    font-size: 20px;
}

.HomePage .HomeCallouts .Callout-Half {
    height: 500px;
    width: 50%;
}

.HomePage .Callout-Half .Callout-Content {
    padding: 20px;
}

.HomePage .Callout-Half .Callout-Content button {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #000000;
    color: #fff;
}

.PastCalloutHeader {
    padding: 20px;
}

.HomePage .stacked {
    flex-direction: column;
}

.HomePage .socialmedia-img {
    margin-right: 20px;
    cursor: pointer;
}

.PastWebinarImages-Wrapper {
    text-align: center;
    height: 100%;
    width: 100%;
    display: table;
}

.PastWebinarImages {
    display: table-cell;
    vertical-align: middle;
}

.PastWebinarImages img {
    width: 100px;
}

.darkgrey-on-grey {
    color: #2e2e2e;
    background-color: #e0dfdf;
}

.white-on-green {
    color: #ffffff;
    background-color: #65ac1e;
    display: none;
}

.white-on-grey {
    color: #ffffff;
    background-color: #666666;
}

.white-on-blue {
    color: #ffffff;
    background-color: #161d2a;
}

@media only screen and (max-width: 768px) {
    .HomePage .flex {
        flex-direction: column;
    }

    .HomePage .HomeCallouts .Callout-Half {
        height: auto;
        width: 100%;
    }

    .HomePage .Callout-Half .Callout-Content button {
        font-size: 18px;
        width: 100%;
    }

    .PastHeader-Images {
        padding-bottom: 0 !important;
    }
    
    .PastHeader-Text {
        padding-top: 0 !important;
    }
}

@media(max-width: 320px){ 
    h1 {
        font-size: 1.7rem;
    }
  }